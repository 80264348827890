import { Box, Image } from '@chakra-ui/react';
import { HeroBackground } from 'common/components/molecules/HeroBackground';
import React from 'react';
import { motion } from 'framer-motion';
import {
  JumbotronCarrouselHomePage,
  JumbotronInfo,
} from './JumbotronCarrouselHomePage/JumbotronCarrouselHomePage';

import { Stepper } from '@irishlifedigitalhub/ds.molecules.stepper';
import arrow from './arrow.svg';
import * as S from './HeroWithCarrousel.styles';
import { useSlideShow } from './hooks/useSlideShow';
export interface IHeroWithCarrousel {
  codename: string;
  lastModified: Date;
  type: string;
  jumbotrons: JumbotronInfo[];
  transition_speed: number;
}

const HeroWithCarrousel: React.FC<any> = (props: IHeroWithCarrousel) => {
  const isEnabledSlideShow: boolean =
    props.jumbotrons.length === 1 ? false : true;

  const { index, goToSlide, prevSlide, nextSlide } = useSlideShow({
    length: props.jumbotrons.length,
    transition_speed: props.transition_speed,
    isEnabledSlideShow: isEnabledSlideShow,
  });

  const StepperJSX = () => {
    return isEnabledSlideShow ? (
      <S.StepperBox display="flex" gap="20px">
        <Image src={arrow} onClick={prevSlide} />
        <S.StepperWrapper>
          <Stepper
            defaultStep={index + 1}
            steps={props.jumbotrons.length}
            leftIndicator={true}
            onChange={(activeStep: number) => goToSlide(activeStep - 1)}
          />
        </S.StepperWrapper>
        <Image src={arrow} transform="rotate(180deg)" onClick={nextSlide} />
      </S.StepperBox>
    ) : (
      <></>
    );
  };

  return (
    <>
      <HeroBackground>
        <motion.div
          id="jumbotrons-carrousel"
          style={{ display: 'flex', flexDirection: 'row', width: '100%' }}
          animate={{ x: -index * 100 + '%' }}
          transition={{ duration: 0.5, ease: 'easeInOut' }}
        >
          {props.jumbotrons.map((jumbotron, i) => (
            <Box
              display="flex"
              flexDirection="column"
              mx="auto"
              minWidth="100%"
              key={`jumbotron-${i}`}
              id={`jumbotron-${i}`}
              className={`jumbotron ${i === index ? 'active' : ''}`}
              transition="transform 0.5s ease"
            >
              <JumbotronCarrouselHomePage
                jumbotronDetails={jumbotron}
                stepper={StepperJSX()}
                jumbotronIndex={i}
              />
            </Box>
          ))}
        </motion.div>
      </HeroBackground>
    </>
  );
};

export { HeroWithCarrousel };
