import { Box } from '@chakra-ui/react';
import { IconButton } from '@irishlife/ilgroupdesignsystem.atoms.icon-button';
import styled from 'styled-components';

const StepperBox = styled(Box)`
  & label {
    color: #fff;

    & > span {
      color: #fff;
    }
  }

  & img {
    cursor: pointer;
  }

  & [data-testid='step-item'] {
    --chakra-colors-vibrant-500: #fff;
`;

const StepperWrapper = styled(Box)`
  display: flex;

  & > div {
    display: flex;
  }
`;

const IconButtonPlayPause = styled(IconButton)`
  & svg {
    stroke: #5261ac;
  }
  :hover {
    & svg {
      stroke: white;
    }
  }
`;

const SecondaryCallToActionBox = styled(Box)`
  & .MuiFab-primary {
    background-color: transparent;

    .MuiFab-label {
      color: var(--Brand-200, #ebedf7);
    }

    & img {
      width: auto !important;
      height: auto !important;
    }
  }
`;

const PrimaryCTA = styled.div`
  & a {
    margin-left: 0 !important;
  }
`;

export {
  StepperBox,
  SecondaryCallToActionBox,
  PrimaryCTA,
  StepperWrapper,
  IconButtonPlayPause,
};
